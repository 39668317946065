@import "../../node_modules/bootstrap/scss/bootstrap";
$neutral: white;
$neutral-contrast: #EBECF0;
$primary-inverted: $neutral;
$hover-bg-inverted: $gray-300;
$headings-color-inverted: $dark;
$brand-primary: #3366FF;
$brand-secondary: #008080;
$brand-tertiary: #00FF33;
$darkShadow: #484f60;
$deepShadow: black;
$innerShadow: inset -4px -4px 6px -1px rgba($neutral, 1),
    inset 2px 2px 8px -1px rgba($darkShadow, 0.5);
$dropShadow: 8px 8px 12px -2px rgba($darkShadow, 0.4),
    -6px -6px 12px -1px rgba($neutral, 1);
$fontFamily: sans-serif;
$fontSize: 18px;
$textColor: #6C7587;
$button-height: 54px;
$button-radius: 20px;
$gray-150: #f1f3f5;

@mixin applyFontStyle() {
    font-family: $fontFamily;
    font-size: $fontSize;
    line-height: $fontSize;
    color: $textColor;
}

%neumorphism-popout-styles {
    @include applyFontStyle;
    padding: 0 8px;
    position: relative;
    border: 3px solid rgba($neutral, 0);
    outline: none;
    text-align: center;
    background-color: $gray-150;
    height: $button-height;
    padding: 0 28px;
    border-radius: $button-radius;
}

%neumorphism-inset-styles {
    @extend %neumorphism-popout-styles;
    box-shadow: $innerShadow;
}


%neumporphism-button-styles {
    @extend %neumorphism-popout-styles;
    transition: all 250ms ease-in-out;
    
    &:hover {
        @extend %neumorphism-inset-styles;
    }
}

.arcane-brand-vivid {
    background-image: linear-gradient(-10deg, $brand-primary, $brand-secondary, $brand-tertiary);
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
}

.arcane-brand-vivid .nav-link {
    color: $primary-inverted;
}

.arcane-brand-vivid .nav-link:hover {
    color: $primary-inverted;
}

.arcane-brand-vivid .btn-primary:not(.uninvert) {
    background-color: $primary-inverted;
    color: $primary;
}

.arcane-brand-vivid .btn-primary:hover:not(.uninvert) {
    background-color: $hover-bg-inverted;
    color: $primary;
}

.arcane-brand-vivid h1, h2, h3, h4, h5, h5, h6:not(.uninvert) {
    color: $headings-color-inverted;
    text-shadow: .5px .5px $primary-inverted;
}

div.neumorphism-card {
    border-radius: 15px;
    background-color: $gray-150;
    box-shadow: 7px 7px 20px $darkShadow,
        inset -2px -2px 7px $darkShadow;
    border-style: none;
}

.arcane-brand-vivid div.neumorphism-card {
    box-shadow: 10px 10px 30px $darkShadow,
        inset -2px -2px 7px $brand-primary;
    border-style: none;
}

div.neumorphism-card button {
    @extend %neumporphism-button-styles;
    box-shadow: $dropShadow;
    
    & > * { vertical-align: middle; }
    & > span:last-child { padding-left: 8px; }
}

.neumorphism-inset {
    @extend %neumorphism-inset-styles;
}

.card-columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 15px;
}

.neumorphism-card-status {
    width: 50%;
    min-width: 200px;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
}