body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.overlay {  
  background-color:rgba(0,0,0,0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0%;
  left: 0px;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.popup-spinner{
  margin-top: 15%;
  top: 0;  
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1110;
}

.form-info {
  padding-top: 5px;
  padding-bottom: 10px;
  border-bottom: 1px solid gray; 
  margin-bottom: 15px;
  font-weight: bold;  
}